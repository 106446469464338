import React, { useMemo, useEffect, useState } from 'react'
import {
  Wrapper,
  Content,
  Details,
  DetailsLeft,
  DetailsRight,
  RoundInfo,
  UsefulInfo,
  InfoItem,
  InfoItemEnd,
  SocialLinks,
  SocialLink,
  AboutWrapper,
  EventsWrapper,
  BannerWrapper,
  BannerContentBg,
  BannerContent,
  Left,
  Right,
  TagItem,
  TagWrapper,
  FeaturedImage,
  DetailsTop,
  VenueEventWrapper,
  VenueFlex,
  EventItems,
  NoEvents,
} from './styled'
import { useStoreon } from 'storeon/react'
import { useParams, useHistory } from 'react-router-dom'
import { genImagePath, isFirstTimeVisitor } from 'utils'
import {
  Icon,
  EventCard,
  DatePicker,
  ClaimVenue,
  Loader,
  SearchModal
} from 'components'
import { useQuery, useLazyQuery, useMutation, gql } from '@apollo/client'
import { format } from 'date-fns'
import { Tooltip } from 'react-tooltip'

const GET_VENUE = gql`
  query getvenue($limit: Int!, $where: venues_bool_exp = {}) {
    venues(limit: $limit, where: $where) {
      id
      address
      country
      state
      city
      name
      phone
      website
      slug
      about
      facebook
      instagram
      twitter
      youtube
      email
      featured_event_id
      major_city_id
      partner_plan_id
      venue_tags {
        tag {
          name
        }
      }
      venue_image {
        name
      }
      user_followed_venues {
        user_id
      }
      events {
        id
      }
    }
  }
`

const GET_EVENTS = gql`
  query getmoreevents($limit: Int, $where: events_bool_exp = {}) {
    events(limit: $limit, order_by: { end_date: asc }, where: $where) {
      id
      location
      end_date
      cover_image_id
      about
      major_city
      min_price
      name
      slug
      start_date
      event_images {
        id
        name
      }
      event_tags {
        tag {
          name
        }
      }
    }
  }
`

const FOLLOW_VENUE = gql`
  mutation followVenue($userId: Int!, $venueId: Int!) {
    insert_user_followed_venues(
      objects: { user_id: $userId, venue_id: $venueId }
    ) {
      affected_rows
    }
  }
`

const UNFOLLOW_VENUE = gql`
  mutation unfollowVenue($userId: Int!, $venueId: Int!) {
    delete_user_followed_venues(
      where: { user_id: { _eq: $userId }, venue_id: { _eq: $venueId } }
    ) {
      affected_rows
    }
  }
`

const GET_USER_ID = gql`
  query users($uid: String!) {
    users(where: { uid: { _eq: $uid } }) {
      id
    }
  }
`

const checkTooltipContent = `
    This check mark verifies that this page is now claimed by the art organization,
    hit the follow button to add this organization to your homescreen.
`

const followTooltipContent = `
    Click to follow this art organization and add them to your homescreen, preferences
    and more so you never miss another upcoming event.
`

const VenueDetails = (props) => {
  const { dispatch, isMobile, user, userData, showSearchModal } = useStoreon(
    'isMobile',
    'user',
    'userData',
    'showSearchModal'
  )
  const { slug } = useParams()
  const history = useHistory()
  const [calendarStart, setCalendarStart] = useState()
  const [calendarEnd, setCalendarEnd] = useState()
  const [venueEventsUpdate, setVenueEventsUpdate] = useState()
  const [showClaimVenue, setShowClaimVenue] = useState(false)
  const [addFollowedVenue] = useMutation(FOLLOW_VENUE)
  const [removeFollowedVenue] = useMutation(UNFOLLOW_VENUE)
  const [followingVenue, setFollowingVenue] = useState(false)

  const { loading: venueLoading, data: venueData } = useQuery(GET_VENUE, {
    variables: {
      limit: 1,
      where: {
        slug: {
          _eq: slug
        }
      }
    }
  })

  const [getVenueEvents, { loading: venueEventsLoading, data: venueEventsData }] =
    useLazyQuery(GET_EVENTS)
  const [getEvents, { loading: eventsLoading, data: eventsData }] =
    useLazyQuery(GET_EVENTS)
  const [getFeaturedEvent, { loading: featuredLoading, data: featuredData }] =
    useLazyQuery(GET_EVENTS)
  const [getUserId, { data: userIdData }] = useLazyQuery(GET_USER_ID)

  useEffect(() => {
    const venue = venueData?.venues[0]

    if (!venueLoading && venueData) {
      const now = new Date()

      if (venue.events.length >= 1) {
        getVenueEvents({
          variables: {
            where: {
              venue_id: {
                _eq: venue?.id
              },
              end_date: {
                _gte: now
              }
            }
          }
        })
      } 

      if (venue.partner_plan_id <= 1 || venue.partner_plan_id === null) {
        getEvents({
          variables: {
            limit: 8,
            where: {
              major_city: {
                _eq: venue?.major_city_id ? venue?.major_city_id - 1 : undefined
              },
              end_date: {
                _gte: now
              }
            }
          }
        })
      }

      if (venue?.partner_plan_id !== null && venue?.featured_event_id) {
        getFeaturedEvent({
          variables: {
            limit: 1,
            where: {
              id: {
                _eq: venue?.featured_event_id
              }
            }
          }
        })
      }
    }
  }, [venueLoading, venueData])

  useEffect(() => {
    if (user?.uid) {
      getUserId({
        variables: {
          uid: user?.uid
        }
      })
    }
  }, [user])

  const venue = venueData?.venues[0]
  const venueEvents = venueEventsData?.events
  const events = eventsData?.events
  const featured = featuredData?.events[0]

  const loading = useMemo(() => {
    if (venue?.partner_plan_id && venue?.featured_event_id) {
      if (venueLoading || featuredLoading) {
        return true
      } else if (!venue || !featured) {
        return true
      }
    } else {
      if (venueLoading) {
        return true
      } else if (!venue) {
        return true
      }
    }

    return false
  }, [venueLoading, featuredLoading, venue, featured])

    const searchModal = useMemo(() => {
        if (showSearchModal) {
          return (
            <SearchModal />
          )            
        }
      }, [showSearchModal])

  const upcomingLoading = useMemo(() => {
    if (venue?.partner_plan_id <= 1 || venue?.partner_plan_id === null) {
      if (venueEventsLoading || eventsLoading) {
        return true
      } else if (!venueEvents || !events) {
        return true
      }      
    } else {
      if (venueEventsLoading) {
        return true
      } else if (!venueEvents) {
        return true
      }
    }

    return false
  }, [eventsLoading, events])

  useEffect(() => {
    if (venue && userIdData) {
      if (
        venue.user_followed_venues.find(
          (v) => v.user_id === userIdData.users[0].id
        )
      ) {
        setFollowingVenue(true)
      } else {
        setFollowingVenue(false)
      }
    }
  }, [venue, userIdData])

  const tags = venue?.venue_tags?.map((t) => t.tag.name)
  const infoData = [
    { data: venue?.phone, svg: 'phone' },
    {
      data: `${venue?.address}, ${venue?.city}, ${venue?.state}`,
      svg: 'place'
    },
    { data: venue?.website, svg: 'external', link: true }
  ]

  const handleCalendarStart = (date) => {
    setCalendarStart(date)
  }

  const handleCalendarEnd = (date) => {
    setCalendarEnd(date)
  }

  const resetVenueEvents = () => {
    const startDate = calendarStart
      ? format(new Date(calendarStart * 1000), 'P')
      : undefined
    const endDate = calendarEnd
      ? format(new Date(calendarEnd * 1000), 'P')
      : undefined

    if (venue?.partner_plan_id !== null) {
      const where = {
        venue_id: {
          _eq: venue?.id
        },
        _or: startDate
          ? [
              {
                _and: [
                  { start_date: { _lte: startDate } },
                  { end_date: { _gte: startDate } }
                ]
              },
              {
                _and: [
                  { start_date: { _lte: endDate } },
                  { end_date: { _gte: endDate } }
                ]
              },
              {
                _and: [
                  { start_date: { _gte: startDate } },
                  { start_date: { _lte: endDate } }
                ]
              },
              {
                _and: [
                  { end_date: { _gte: startDate } },
                  { end_date: { _lte: endDate } }
                ]
              }
            ]
          : [
              {
                _and: [{ end_date: { _gte: format(new Date(), 'P') } }]
              }
            ]
      }

      setVenueEventsUpdate(!venueEventsUpdate)

      getEvents({
        variables: {
          where: where
        }
      })
    }
  }

  useEffect(() => {
    if (calendarStart === undefined && calendarEnd === undefined) {
      resetVenueEvents()
    }
  }, [calendarStart, calendarEnd])

  const dateCleared = () => {
    setCalendarStart(undefined)
    setCalendarEnd(undefined)
  }

  const getImagePath = () => {
    const coverImageName = featured?.event_images
      .filter((i) => i.id === featured.cover_image_id)
      .map((i) => i.name)

    return genImagePath({
      ownerType: 1,
      ownerId: featured?.id,
      name: coverImageName
    })
  }

  const renderTag = (item, idx) => {
    return (
      <TagItem key={idx}>
        <span>{item}</span>
      </TagItem>
    )
  }

  const followVenue = () => {
    if (!userIdData) {
      dispatch('setShowSignupOptin', true)
    } else {
      if (venue.id && userIdData.users[0].id) {
        if (!followingVenue) {
          addFollowedVenue({
            variables: {
              userId: userIdData.users[0].id,
              venueId: venue.id
            }
          })

          setFollowingVenue(true)
        } else {
          removeFollowedVenue({
            variables: {
              userId: userIdData.users[0].id,
              venueId: venue.id
            }
          })

          setFollowingVenue(false)
        }
      }
    }
  }

  const banner = () => {
    return (
      <BannerWrapper
        image={`https://rca-s3.imgix.net/4/${venue?.id}/${venue?.venue_image?.name}`}
        isMobile={isMobile}
      >
        <BannerContentBg>
          <BannerContent isMobile={isMobile}>
            <Left>
              <h1>
                <div>{venue?.name}</div>
                {venue?.partner_plan_id !== null && (
                  <>
                    <div id={'icon-circle-check'}>
                      <Icon name={'circleCheck'} />
                    </div>
                    <Tooltip
                      className="check-tooltip"
                      anchorSelect="#icon-circle-check"
                      content={checkTooltipContent}
                      place="bottom"
                      offset={-65}
                      opacity={1}
                    />
                  </>
                )}
              </h1>
              {tags && (
                <TagWrapper>{tags.slice(0, 3).map(renderTag)}</TagWrapper>
              )}
              {isMobile && (
                <>
                  {userData && userData.isAdmin && (
                    <button
                      style={{ marginBottom: '10px', justifyContent: 'center' }}
                      onClick={() =>
                        history.push(`/venue/${venue.slug}/dashboard/profile`)
                      }
                    >
                      <Icon name="venue" />
                      Dashboard
                    </button>
                  )}
                  <button
                    onClick={() => {
                      venue.partner_plan_id
                        ? followVenue()
                        : setShowClaimVenue(true)
                    }}
                    rel="noreferrer"
                    target="_blank"
                    style={{ justifyContent: 'center', width: '55%' }}
                  >
                    {venue?.partner_plan_id ? (
                      <Icon name={followingVenue ? 'unfollow' : 'follow'} />
                    ) : (
                      ''
                    )}
                    {venue?.partner_plan_id
                      ? followingVenue
                        ? 'Unfollow'
                        : 'Follow'
                      : 'Claim'}
                  </button>
                </>
              )}
            </Left>
            {!isMobile && (
              <Right>
                {userData && userData.isAdmin && (
                  <button
                    style={{ marginBottom: '10px' }}
                    onClick={() =>
                      history.push(`/venue/${venue.slug}/dashboard/profile`)
                    }
                  >
                    <Icon name="venue" />
                    Dashboard
                  </button>
                )}
                <button
                  id="follow-button"
                  style={{ marginBottom: '10px' }}
                  onClick={() => {followVenue()}}
                >
                  <Icon name={followingVenue ? 'unfollow' : 'follow'} />

                  {followingVenue
                    ? 'Unfollow'
                    : 'Follow'
                  }
                </button>
                {!venue.partner_plan_id && (
                  <button
                    id="claim-button"
                    style={{ textAlign: 'center', display: 'flex', justifyContent: 'center'  }}
                    onClick={() => {setShowClaimVenue(true)}}
                  >
                    Claim
                  </button>
                )}
                {venue.partner_plan_id && (
                  <Tooltip
                    className="follow-tooltip"
                    anchorSelect="#follow-button"
                    content={followTooltipContent}
                    place="bottom"
                    offset={6}
                    opacity={1}
                  />
                )}
              </Right>
            )}
          </BannerContent>
        </BannerContentBg>
      </BannerWrapper>
    )
  }

  const renderSocialLink = (item, idx) => {
    const { to, svg } = item

    if (!to) {
      return null
    }

    return (
      <SocialLink key={idx} href={to} target="_blank">
        <Icon name={`${svg}`} />
      </SocialLink>
    )
  }

  const socialLinks = () => {
    const links = [
      { to: venue?.facebook ?? undefined, svg: 'facebook' },
      { to: venue?.instagram ?? undefined, svg: 'instagram' },
      { to: venue?.twitter ?? undefined, svg: 'twitter' },
      { to: venue?.youtube ?? undefined, svg: 'youtube' },
      { to: venue?.email ? `mailto:${venue?.email}` : undefined, svg: 'email' }
    ]

    return <SocialLinks>{links.map(renderSocialLink)}</SocialLinks>
  }

  const renderInfo = (item, idx) => {
    const { data, svg, link } = item

    if (!data) {
      return null
    }

    return (
      <InfoItem key={idx}>
        <Icon name={svg} />
        {link ? (
          <a href={data} target="_blank" rel="noreferrer">
            {data}
          </a>
        ) : (
          <span>{data}</span>
        )}
      </InfoItem>
    )
  }

  const renderRight = () => {
    if (isMobile) {
      return (
        <DetailsRight>
          <RoundInfo>
            <UsefulInfo>
              <InfoItemEnd>
                <h3>Useful Info</h3>
              </InfoItemEnd>
              {infoData.map(renderInfo)}
              <InfoItemEnd>{socialLinks()}</InfoItemEnd>
            </UsefulInfo>
          </RoundInfo>
        </DetailsRight>
      )
    }

    return (
      <DetailsRight>
        <RoundInfo>
          <UsefulInfo>
            <InfoItemEnd>
              <h3>Useful Info</h3>
            </InfoItemEnd>
            {infoData.map(renderInfo)}
            <InfoItemEnd>{socialLinks()}</InfoItemEnd>
          </UsefulInfo>
        </RoundInfo>
      </DetailsRight>
    )
  }

  const renderDetails = () => {
    return (
      <Details>
        <DetailsTop>
          <DetailsLeft>
            <h2>About the Organization</h2>
            <AboutWrapper>
              {venue?.about
                ? venue?.about
                    .split('\n')
                    .map((i, idx) => (i ? <p key={idx}>{i}</p> : <br />))
                : `No description of this venue exists. If you are the venue then claim this page or contact Red Curtain Addict to get your information out there! `}
            </AboutWrapper>
          </DetailsLeft>
          {renderRight()}
        </DetailsTop>
        {venue.partner_plan_id !== null &&
          venue.partner_plan_id >= 2 &&
          venue?.featured_event_id && (
            <DetailsLeft>
              <h2 style={{ marginBottom: '20px' }}>Featured Event</h2>
              <AboutWrapper>
                {isMobile && (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <FeaturedImage
                      image={`${getImagePath()}?fit=crop&height=425&width=465&auto=compression`}
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                      }}
                    >
                      <h2 style={{ marginBottom: '20px' }}>{featured.name}</h2>
                      {featured.about}
                    </div>
                  </div>
                )}
                {!isMobile && (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <FeaturedImage
                      image={`${getImagePath()}?fit=crop&height=425&width=465&auto=compression`}
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '130%'
                      }}
                    >
                      <h2 style={{ marginBottom: '20px' }}>{featured.name}</h2>
                      {featured.about}
                    </div>
                  </div>
                )}
              </AboutWrapper>
            </DetailsLeft>
          )}
      </Details>
    )
  }

  const renderEvent = (item, idx) => {
    return <EventCard key={idx} data={item} />
  }

  const renderVenueEvents = () => {

    if (venueEvents?.length >= 1) {
      return (
        <VenueEventWrapper>
          <VenueFlex>
            <h3 id="upcoming-events">Upcoming Events</h3>
              <button>
                <DatePicker
                  startDate={calendarStart}
                  endDate={calendarEnd}
                  onStartChange={(value) => handleCalendarStart(value)}
                  onEndChange={(value) => handleCalendarEnd(value)}
                  calendar={true}
                  leftAdjust={true}
                  onSearchPassed={true}
                  onSearch={resetVenueEvents}
                  dateText={'Select Date'}
                  onDateClear={dateCleared}
                />
              </button>
          </VenueFlex>
          {upcomingLoading ? (
            <div
              style={{
                height: '200px',
                margin: 'auto',
                fontSize: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <p>Loading</p>
              <div style={{ marginLeft: '10px' }}>
                <Loader />
              </div>
            </div>
          ) : (
            <>
              {venueEvents.length > 0 ? (
                <EventsWrapper>
                  <EventItems>
                    <div>{venueEvents.map(renderEvent)}</div>
                  </EventItems>
                </EventsWrapper>
              ) : (
                <NoEvents>
                  <p>
                    Sorry, no events during this time. Please try selecting more
                    dates.
                  </p>
                </NoEvents>
              )}
            </>
          )}
        </VenueEventWrapper>
      )
    }
  }

  const renderNearEvents = () => {
    if (
      (venue.partner_plan_id <= 1 || venue.partner_plan_id === null) &&
      events?.length > 0
    ) {
      return (
        <VenueEventWrapper>
          <VenueFlex>
            <h3 id="upcoming-events">Upcoming Events Near You</h3>
          </VenueFlex>
          {upcomingLoading ? (
            <div
              style={{
                height: '200px',
                margin: 'auto',
                fontSize: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <p>Loading</p>
              <div style={{ marginLeft: '10px' }}>
                <Loader />
              </div>
            </div>
          ) : (
            <EventsWrapper>
              <EventItems>
                <div>{events.slice(0, 12).map(renderEvent)}</div>
              </EventItems>
            </EventsWrapper>
          )}
        </VenueEventWrapper>
      )
    }
  }

  if (loading) {
    return (
      <div
        style={{
          height: '100vh',
          margin: 'auto',
          fontSize: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <p>Loading</p>
        <div style={{ marginLeft: '10px' }}>
          <Loader />
        </div>
      </div>
    )
  }

  return (
      <Wrapper>
        {banner()}
        <Content>
          {renderDetails()}
          {renderVenueEvents()}
          {renderNearEvents()}
        </Content>
        <ClaimVenue
          open={showClaimVenue}
          onClose={() => setShowClaimVenue(false)}
        />

        {searchModal}
      </Wrapper>
    )
}

export default VenueDetails
