import React, { useState, useEffect, useMemo } from 'react'

import {
  BannerImageWrapper,
  MainWrapper,
  BannerTitle,
  Overlay,
  TitleContainer,
  PreferenceCardWrapper,
  CarouselWrapper,
  CarouselRow,
  SearchWrapper,
  MobileSection,
  CarouselTitleWrapper,
  EventCardWrapper,
  BannerContent
} from './styled'

import {
  Carousel,
  Loader,
  EventSearchBar,
  SearchModal,
  EventCard,
  MobileEventCard,
  PreferenceCard
} from 'components'
import { useQuery, useLazyQuery, gql } from '@apollo/client'
import { format } from 'date-fns'
import { getPrefs } from 'apis'
import { useStoreon } from 'storeon/react'
import { preTags, topics } from 'constant'
import { useLocation } from 'react-router-dom'

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const GET_VENUES = gql`
  query getvenues($where: venues_bool_exp = {}) {
    venues(where: $where) {
      id
      address
      country
      state
      city
      name
      phone
      website
      slug
      about
      facebook
      instagram
      twitter
      youtube
      email
      featured_event_id
      major_city_id
      partner_plan_id
      major_city {
        cid
      }
      partner_plan {
        page_follow
      }
      venue_tags {
        tag {
          name
        }
        tag_id
      }
      venue_image {
        name
      }
      user_followed_venues {
        user_id
      }
    }
  }
`

const GET_EVENTS = gql`
  query getevents($where: events_bool_exp = {}) {
    events(order_by: { start_date: asc }, where: $where) {
      id
      location
      end_date
      cover_image_id
      about
      major_city
      min_price
      name
      slug
      start_date
      event_images {
        id
        name
      }
      event_tags {
        tag {
          name
        }
        tag_id
      }
    }
  }
`

const GET_USER_ID = gql`
  query users($uid: String!) {
    users(where: { uid: { _eq: $uid } }) {
      id
    }
  }
`

const GET_FOLLOWED_VENUES = gql`
  query venues($userId: Int!) {
    venues(
      order_by: { name: asc }
      where: { user_followed_venues: { user_id: { _eq: $userId } } }
    ) {
      id
      name
      city
      state
      about
      slug
      venue_tags {
        tag {
          name
        }
      }
      venue_image {
        name
      }
      user_followed_venues {
        user_id
      }
    }
  }
`

const SomethingNew = () => {
  const [currentTags, setCurrentTags] = useState([])
  const [somethingNewTags, setSomethingNewTags] = useState([])
  const [followedItems, setFollowedItems] = useState([])
  const [sortingOrgs, setSortingOrgs] = useState(true)
  const [sortedOrgs, setSortedOrgs] = useState([])
  const [sortingEvents, setSortingEvents] = useState(true)
  const [sortedEvents, setSortedEvents] = useState([])
  const { user, userData, loading, isMobile, showSearchModal } = useStoreon(
    'user',
    'userData',
    'loading',
    'isMobile',
    'showSearchModal'
  )
  const { location } = useLocation()
  const filteredPreTags = preTags.filter(
    (pt) => pt.category === 'Type' || pt.category === 'Category'
  )
  const filterAgainstTags = filteredPreTags.flatMap((pt) =>
    pt.tags.map((tag) => ({
      label: tag.label,
      value: tag.value
    }))
  )
  const newDate = format(new Date(), 'P')
  const tagNames = userData?.tags?.map((i) => i.label)
  const commonTags =
    tagNames?.filter((label) =>
      filterAgainstTags.some(
        (tag) => tag.label.toLowerCase() === label.toLowerCase()
      )
    ) || []
  const cityIds = userData?.cities || []
  const userCityIds = userData?.cities?.map((id) => id + 1) || []

  useEffect(() => {
    const fetchCurrentTags = async () => {
      if (user && user.getIdToken) {
        const token = await user.getIdToken()
        const data = await getPrefs({ token })
        const { tags: t } = data
        setCurrentTags(t.map((t) => t.value))
      }
    }

    fetchCurrentTags()
  }, [user])

  useEffect(() => {
    const filteredTags = filterAgainstTags.filter(
      (tag) => !currentTags.includes(tag.value)
    )

    setSomethingNewTags(filteredTags)
  }, [currentTags])

  const { loading: venuesLoading, data: venuesData } = useQuery(GET_VENUES, {
    variables: {
      where: {
        venue_tags: userData?.tags?.length
          ? {
              tag: {
                name: {
                  _nin: commonTags
                }
              }
            }
          : undefined,
        major_city: {
          cid: userData?.cities?.length
            ? {
                _in: cityIds
              }
            : undefined
        }
      }
    }
  })
  const [getOrgsFromOtherCities, { data: otherCityOrgsData }] = useLazyQuery(
    GET_VENUES,
    {
      variables: {
        where: {
          _or: [
            { major_city_id: { _nin: userCityIds } },
            { major_city_id: { _is_null: true } }
          ],
          venue_tags: userData?.tags?.length
            ? {
                tag: {
                  name: {
                    _nin: commonTags
                  }
                }
              }
            : undefined
        }
      }
    }
  )

  const { loading: eventsLoading, data: eventsData } = useQuery(GET_EVENTS, {
    variables: {
      where: {
        _or: [
          { start_date: { _gte: newDate } },
          { end_date: { _gte: newDate } }
        ],
        event_tags: userData?.tags?.length
          ? {
              tag: {
                name: {
                  _nin: commonTags
                }
              }
            }
          : undefined,
        major_city: userData?.cities?.length
          ? {
              _in: cityIds
            }
          : undefined
      }
    }
  })
  const [
    getEventsFromOtherCities,
    { loading: eventsNinCityLoading, data: eventsNinCityData }
  ] = useLazyQuery(GET_EVENTS, {
    variables: {
      where: {
        _or: [
          { start_date: { _gte: newDate } },
          { end_date: { _gte: newDate } }
        ],
        event_tags: userData?.tags?.length
          ? {
              tag: {
                name: {
                  _nin: commonTags
                }
              }
            }
          : undefined,
        major_city: userData?.cities?.length
          ? {
              _nin: cityIds
            }
          : undefined
      }
    }
  })

  useEffect(() => {
    if (userData && userData.cities && userData.cities.length >= 1) {
      getOrgsFromOtherCities()
    }
  }, [userData])

  useEffect(() => {
    if (venuesData && otherCityOrgsData) {
      setSortedOrgs([...venuesData.venues, ...otherCityOrgsData.venues])

      setSortingOrgs(false)
    } else if (venuesData) {
      setSortedOrgs([...venuesData.venues])

      setSortingOrgs(false)
    }
  }, [venuesData, otherCityOrgsData])

  useEffect(() => {
    if (userData && userData.cities && userData.cities.length >= 1) {
      getEventsFromOtherCities()
    }
  }, [userData])

  useEffect(() => {
    if (userData && userData.cities && userData.cities.length >= 1) {
      setSortedEvents([
        ...(eventsData?.events || []),
        ...(eventsNinCityData?.events || [])
      ])
      setSortingEvents(false)
    } else {
      setSortedEvents([...(eventsData?.events || [])])
      setSortingEvents(false)
    }
  }, [eventsData, eventsNinCityData])

  const [getUserId, { data: userIdData }] = useLazyQuery(GET_USER_ID)
  const [
    getFollowedVenues,
    { loading: followedVenuesLoading, data: followedVenuesData }
  ] = useLazyQuery(GET_FOLLOWED_VENUES, { fetchPolicy: 'network-only' })

  useEffect(() => {
    if (userIdData) {
      getFollowedVenues({
        variables: {
          userId: userIdData.users[0]?.id
        }
      })
    }
  }, [userIdData, location])

  const dataLoading = useMemo(() => {
    if (loading || venuesLoading || eventsLoading) {
      return true
    }

    if (userData && sortingOrgs && sortingEvents) {
      return true
    }

    return false
  }, [
    loading,
    venuesLoading,
    eventsLoading,
    userData,
    sortingOrgs,
    sortingEvents
  ])

  const handleFollowClick = (venue_id, action) => {
    if (action) {
      setFollowedItems((prevItems) => [...prevItems, venue_id])
    } else {
      setFollowedItems((prevItems) => prevItems.filter((id) => id !== venue_id))
    }
  }

  const renderVenue = (item, idx) => {
    let isFollowed = followedItems.includes(item.id)

    return (
      <PreferenceCard
        id={item.id}
        userId={userIdData ? userIdData.users[0].id : ''}
        followed={isFollowed}
        followAllowed={true}
        key={idx}
        ownerId={item.id}
        ownerType={4}
        name={item.venue_image?.name || ''}
        label={item.name}
        actionUrl={'/venue/' + item.slug}
        onFollow={(res) => handleFollowClick(item.id, res)}
      />
    )
  }

  const renderEvent = (item, idx) => {
    return <EventCard key={idx} data={item} />
  }

  const renderMobileEvent = (item, idx) => {
    return <MobileEventCard key={idx} data={item} />
  }

  useEffect(() => {
    if (user?.uid) {
      getUserId({
        variables: {
          uid: user?.uid
        }
      })
    }
  }, [user])

  useEffect(() => {
    if (userIdData) {
      const newFollowedItems = []

      somethingNewTags.forEach((tagItem, idx) => {
        const filteredVenues = venuesData?.venues?.filter((venue) =>
          venue.venue_tags.some((vt) => vt.tag_id === Number(tagItem.value))
        )

        filteredVenues?.forEach((filteredItem) => {
          if (filteredItem && userIdData) {
            const userFollowedVenue = followedVenuesData?.venues?.find(
              (item) => item.id === filteredItem.id
            )

            if (userFollowedVenue && !followedItems.includes(filteredItem.id)) {
              newFollowedItems.push(filteredItem.id)
            }
          }
        })
      })

      setFollowedItems([...followedItems, ...newFollowedItems])
    }
  }, [userIdData, location, venuesData, followedVenuesLoading])

  const searchModal = useMemo(() => {
    if (showSearchModal) {
      return <SearchModal />
    }
  }, [showSearchModal])

  if (dataLoading || !sortedOrgs || !sortedEvents) {
    return (
      <div
        style={{
          height: '100vh',
          margin: 'auto',
          fontSize: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <p>Loading</p>
        <div style={{ marginLeft: '10px' }}>
          <Loader />
        </div>
      </div>
    )
  }

  return (
    <MainWrapper>
      <BannerImageWrapper>
        <Overlay />
        <BannerContent>
          <SearchWrapper>
            <EventSearchBar reroute />
          </SearchWrapper>
          <TitleContainer>
            <BannerTitle>
              <h1>Try Something NEW!</h1>
              <p>
                Embrace the unexpected and explore art organizations that are
                currently outside your followed list or preferences!
                <br /> <br />
                Simply scroll and click to begin your next great arts adventure!
              </p>
            </BannerTitle>
          </TitleContainer>
        </BannerContent>
      </BannerImageWrapper>
      <CarouselTitleWrapper>
        <h1>Art Organizations to Explore!</h1>
      </CarouselTitleWrapper>
      <CarouselWrapper>
        {sortedOrgs && !isMobile && (
          <React.Fragment>
            {topics.map((item, idx) => {
              const filtered = sortedOrgs.filter((v) => {
                const hasTag = v.venue_tags.some(
                  (vt) => vt.tag_id === Number(item.value)
                )
                if (userIdData) {
                  const notInSomethingNewTags = v.venue_tags.every(
                    (vt) =>
                      !somethingNewTags.some(
                        (tag) => Number(tag.value) === vt.tag_id
                      )
                  )
                  return hasTag && notInSomethingNewTags
                }
                return hasTag
              })

              if (filtered.length < 1) {
                return null
              }

              // if (item && userIdData) {
              //   if (
              //     item.user_followed_venues.find(
              //       (v) => v.user_id === userIdData.users[0].id
              //     )
              //   ) {
              //     setFollowedItems([...followedItems, item.id])
              //   }
              // }

              if (filtered.length <= 4) {
                return (
                  <CarouselRow key={idx}>
                    <Carousel label={capitalizeFirstLetter(item.label)}>
                      <PreferenceCardWrapper>
                        {filtered.slice(0, 4).map(renderVenue)}
                      </PreferenceCardWrapper>
                    </Carousel>
                  </CarouselRow>
                )
              } else if (filtered.length >= 4 && filtered.length < 8) {
                return (
                  <CarouselRow key={idx}>
                    <Carousel label={capitalizeFirstLetter(item.label)}>
                      <PreferenceCardWrapper>
                        {filtered.slice(0, 4).map(renderVenue)}
                      </PreferenceCardWrapper>
                      <PreferenceCardWrapper>
                        {filtered.slice(4, 8).map(renderVenue)}
                      </PreferenceCardWrapper>
                    </Carousel>
                  </CarouselRow>
                )
              } else if (filtered.length >= 8) {
                return (
                  <CarouselRow key={idx}>
                    <Carousel label={capitalizeFirstLetter(item.label)}>
                      <PreferenceCardWrapper>
                        {filtered.slice(0, 4).map(renderVenue)}
                      </PreferenceCardWrapper>
                      <PreferenceCardWrapper>
                        {filtered.slice(4, 8).map(renderVenue)}
                      </PreferenceCardWrapper>
                      <PreferenceCardWrapper>
                        {filtered.slice(8, 12).map(renderVenue)}
                      </PreferenceCardWrapper>
                    </Carousel>
                  </CarouselRow>
                )
              }
            })}
          </React.Fragment>
        )}

        {/* IF MOBILE */}
        {sortedOrgs && isMobile && (
          <React.Fragment>
            {topics.map((item, idx) => {
              const filtered = sortedOrgs.filter((v) => {
                const hasTag = v.venue_tags.some(
                  (vt) => vt.tag_id === Number(item.value)
                )
                if (userIdData) {
                  const notInSomethingNewTags = v.venue_tags.every(
                    (vt) =>
                      !somethingNewTags.some(
                        (tag) => Number(tag.value) === vt.tag_id
                      )
                  )
                  return hasTag && notInSomethingNewTags
                }
                return hasTag
              })

              if (filtered.length < 1) {
                return null
              }

              return (
                <MobileSection itemWidth={'48vw'} key={idx}>
                  <h3>{capitalizeFirstLetter(item.label)}</h3>
                  <PreferenceCardWrapper $isMobile={isMobile}>
                    {filtered?.slice(0, 12).map(renderVenue)}
                  </PreferenceCardWrapper>
                </MobileSection>
              )

              // if (filtered.length <= 3) {
              //   return (
              //     <CarouselRow key={idx}>
              //       <Carousel label={capitalizeFirstLetter(item.label)}>
              //         <PreferenceCardWrapper $isMobile={isMobile}>
              //           {filtered.slice(0, 3).map(renderVenue)}
              //         </PreferenceCardWrapper>
              //       </Carousel>
              //     </CarouselRow>
              //   )
              // } else if (filtered.length >= 3 && filtered.length <= 6) {
              //   return (
              //     <CarouselRow key={idx}>
              //       <Carousel label={capitalizeFirstLetter(item.label)}>
              //         <PreferenceCardWrapper $isMobile={isMobile}>
              //           {filtered.slice(0, 3).map(renderVenue)}
              //         </PreferenceCardWrapper>
              //         <PreferenceCardWrapper $isMobile={isMobile}>
              //           {filtered.slice(3, 6).map(renderVenue)}
              //         </PreferenceCardWrapper>
              //       </Carousel>
              //     </CarouselRow>
              //   )
              // } else if (filtered.length >= 6 && filtered.length <= 12) {
              //   return (
              //     <CarouselRow key={idx}>
              //       <Carousel label={capitalizeFirstLetter(item.label)}>
              //         <PreferenceCardWrapper $isMobile={isMobile}>
              //           {filtered.slice(0, 3).map(renderVenue)}
              //         </PreferenceCardWrapper>
              //         <PreferenceCardWrapper $isMobile={isMobile}>
              //           {filtered.slice(3, 6).map(renderVenue)}
              //         </PreferenceCardWrapper>
              //         <PreferenceCardWrapper $isMobile={isMobile}>
              //           {filtered.slice(6, 9).map(renderVenue)}
              //         </PreferenceCardWrapper>
              //       </Carousel>
              //     </CarouselRow>
              //   )
              // } else if (filtered.length >= 12) {
              //   return (
              //     <CarouselRow key={idx}>
              //       <Carousel label={capitalizeFirstLetter(item.label)}>
              //         <PreferenceCardWrapper $isMobile={isMobile}>
              //           {filtered.slice(0, 3).map(renderVenue)}
              //         </PreferenceCardWrapper>
              //         <PreferenceCardWrapper $isMobile={isMobile}>
              //           {filtered.slice(3, 6).map(renderVenue)}
              //         </PreferenceCardWrapper>
              //         <PreferenceCardWrapper $isMobile={isMobile}>
              //           {filtered.slice(6, 9).map(renderVenue)}
              //         </PreferenceCardWrapper>
              //         <PreferenceCardWrapper $isMobile={isMobile}>
              //           {filtered.slice(9, 12).map(renderVenue)}
              //         </PreferenceCardWrapper>
              //       </Carousel>
              //     </CarouselRow>
              //   )
              // }
            })}
          </React.Fragment>
        )}
      </CarouselWrapper>

      <CarouselTitleWrapper>
        <h1>Events to Explore by Genre!</h1>
      </CarouselTitleWrapper>
      <CarouselWrapper>
        {sortedEvents.length && !isMobile && (
          <React.Fragment>
            {topics.map((item, idx) => {
              const filtered = sortedEvents.filter((e) => {
                const hasTag = e.event_tags.some(
                  (eventTag) => Number(eventTag.tag_id) === Number(item.value)
                )
                if (userIdData) {
                  const notInSomethingNewTags = e.event_tags.every(
                    (eventTag) =>
                      !somethingNewTags.some(
                        (tag) => tag.label === eventTag.tag.name
                      )
                  )
                  return hasTag && notInSomethingNewTags
                }
                return hasTag
              })

              if (filtered.length < 1) {
                return null
              }

              // if (item && userIdData) {
              //   if (
              //     item.user_followed_venues.find(
              //       (v) => v.user_id === userIdData.users[0].id
              //     )
              //   ) {
              //     setFollowedItems([...followedItems, item.id])
              //   }
              // }

              if (filtered.length <= 4) {
                return (
                  <CarouselRow key={idx}>
                    <Carousel label={capitalizeFirstLetter(item.label)}>
                      <EventCardWrapper>
                        {filtered.slice(0, 4).map(renderEvent)}
                      </EventCardWrapper>
                    </Carousel>
                  </CarouselRow>
                )
              } else if (filtered.length >= 4 && filtered.length < 8) {
                return (
                  <CarouselRow key={idx}>
                    <Carousel label={capitalizeFirstLetter(item.label)}>
                      <EventCardWrapper>
                        {filtered.slice(0, 4).map(renderEvent)}
                      </EventCardWrapper>
                      <EventCardWrapper>
                        {filtered.slice(4, 8).map(renderEvent)}
                      </EventCardWrapper>
                    </Carousel>
                  </CarouselRow>
                )
              } else if (filtered.length >= 8) {
                return (
                  <CarouselRow key={idx}>
                    <Carousel label={capitalizeFirstLetter(item.label)}>
                      <EventCardWrapper>
                        {filtered.slice(0, 4).map(renderEvent)}
                      </EventCardWrapper>
                      <EventCardWrapper>
                        {filtered.slice(4, 8).map(renderEvent)}
                      </EventCardWrapper>
                      <EventCardWrapper>
                        {filtered.slice(8, 12).map(renderEvent)}
                      </EventCardWrapper>
                    </Carousel>
                  </CarouselRow>
                )
              }
            })}
          </React.Fragment>
        )}

        {/* IF MOBILE */}
        {sortedEvents.length && isMobile && (
          <React.Fragment>
            {topics.map((item, idx) => {
              const filtered = sortedEvents.filter((e) => {
                const hasTag = e.event_tags.some(
                  (eventTag) => Number(eventTag.tag_id) === Number(item.value)
                )
                if (userIdData) {
                  const notInSomethingNewTags = e.event_tags.every(
                    (eventTag) =>
                      !somethingNewTags.some(
                        (tag) => tag.label === eventTag.tag.name
                      )
                  )
                  return hasTag && notInSomethingNewTags
                }
                return hasTag
              })

              if (filtered.length < 1) {
                return null
              }

              return (
                <MobileSection itemWidth={'44vw'} key={idx}>
                  <h3>{capitalizeFirstLetter(item.label)}</h3>
                  <EventCardWrapper $isMobile={isMobile}>
                    {filtered?.slice(0, 12).map(renderMobileEvent)}
                  </EventCardWrapper>
                </MobileSection>
              )
            })}
          </React.Fragment>
        )}
      </CarouselWrapper>

      {searchModal}
    </MainWrapper>
  )
}

export default SomethingNew
