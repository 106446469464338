import styled from 'styled-components'
import { maxDesktopCss, breakpoints } from 'constant'

const { md } = breakpoints

export const ArticlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-bottom: 80px;
`

export const ArticleContent = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  ${maxDesktopCss}
  padding-bottom: 80px;
`

export const LatestArticle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > h2 {
    margin-bottom: 12px;
    font-size: 32px;
  }

  > a {
    margin-top: 32px;
    width: 183px;
    max-width: 100%;
    align-self: flex-start;
  }

  ${md} {
    > h2 {
      font-size: 24px;
    }

    > a {
      width: 100%;
    }
  }
`

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${maxDesktopCss}
  margin-bottom: 24px;

  > h2 {
    display: flex;
    font-size: 32px;
    margin-bottom: 26px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    > a {
      text-decoration: underline;
      font-size: 16px;
    }
  }

  > div {
    display: flex;

    > a {
      flex: 1;

      &:first-child {
        margin-right: 16px;

        ${md} {
          margin-right: 0px;
        }
      }
    }
  }
`

export const AllCards = styled.div`
  ${maxDesktopCss}
  display: flex;
  flex-wrap: wrap;

  > a {
    width: calc(50% - 8px);
    margin-bottom: 16px;

    &:nth-child(odd) {
      margin-right: 16px;
    }
  }

  ${md} {
    > a {
      width: 100%;

      &:nth-child(odd) {
        margin-right: 0px;
      }
    }
  }
`

export const AritcleHeader = styled.div`
  ${maxDesktopCss}
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 24px;
`
