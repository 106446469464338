import React from 'react'
import {
  RcaLogo,
  ContentWrapper,
  TextWrapper,
  ButtonWrapper,
  ItalicsWrapper
} from './styled'
import { Button } from 'components'
import { Link } from 'react-router-dom'

const PrefenceThankYou = () => {
  return (
    <ContentWrapper>
      <RcaLogo />

      <h1>Thank You!</h1>
      <h4>Your arts performances have been updated.</h4>
      <TextWrapper>
        <p>
          Your preferences will be reflected on your homepage experience, and
          we'll send you curated performances for you to check out via email.
        </p>
      </TextWrapper>
      <h4>What's next?</h4>
      <TextWrapper>
        <p>
          Click below to head to your customized homepage to start planning your
          next arts outing!
        </p>
      </TextWrapper>
      <ButtonWrapper>
        <Button href="/">Get Started</Button>
      </ButtonWrapper>
      <Link to="/preferences" style={{ marginBottom: '10px' }}>
        View Preferences
      </Link>
      <ItalicsWrapper>
        <p>
          To make changes to your preferences, simply click "Preferences" at the
          top right corner at any time.
        </p>
      </ItalicsWrapper>
    </ContentWrapper>
  )
}

export default PrefenceThankYou
