import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {Layout} from 'scenes'
import {Frontload} from 'react-frontload'
import {StoreContext} from 'storeon/react'
import createStore from 'stores'
import {GlobalStyle} from './styled'
import routes from './routes'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
// import { useHydrateCache } from 'gql'
import {ApolloProvider} from '@apollo/client';
import client from './ApolloClient';
import { MemberstackProvider } from '@memberstack/react'
import { Toaster } from 'react-hot-toast';

if (firebase.apps.length === 0) {
  firebase.initializeApp({
    apiKey: 'AIzaSyBLhZrG4sB42R_xkwMSgoDoWF1gamxh5jc',
    authDomain: 'new.redcurtainaddict.com',
    databaseURL: 'https://red-curtain-addict-default-rtdb.firebaseio.com/',
    projectId: 'red-curtain-addict',
    storageBucket: 'red-curtain-addict.appspot.com',
    messagingSenderId: '484768154550'
  })
}

const App = (props) => {
  const config = { publicKey: "pk_sb_b9107e878c33e33c420d" }
  let {store} = props

  // useHydrateCache({
  //   cacheSnapshot: isSsr ? '' : window.__GQLESS_INITIAL_STATE__,
  //   shouldRefetch: false
  // })

  if (!store) {
    store = createStore()
  }

  return (
    <MemberstackProvider config={config}>
      <ApolloProvider client={client}>
        <Frontload>
          <StoreContext.Provider value={store}>
            <Layout>
              <Toaster position="top-center"/>
              <Switch>
                {routes.map((item, idx) => <Route key={idx} {...item} />)}
              </Switch>
              <GlobalStyle />
            </Layout>
          </StoreContext.Provider>
        </Frontload>
      </ApolloProvider>
    </MemberstackProvider>
  )
}

export default App
