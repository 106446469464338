import React from 'react'
import {
    RcaLogo,
    ContentWrapper,
    TextWrapper,
    ButtonWrapper
} from './styled'
import { Button } from 'components'
import { Link } from 'react-router-dom'


const SignUpThankYou = () => {
    return (
        <ContentWrapper>
            <RcaLogo />

            <h1>Thank You!</h1>
            <h4>Welcome to the "Arts Addict" community!</h4>
            <TextWrapper>
                <p>Let's embark on a journey to tailor your next arts outing experience - just for you! Ready to get started by updating your personal preferences?</p>
            </TextWrapper>
            <ButtonWrapper>
                <Button href='/signup/preferences'>
                Let's get started!
                </Button>                
            </ButtonWrapper>
            <Link to='/'>Remind me later</Link>
        </ContentWrapper>
    );
}

export default SignUpThankYou;