import { useEffect, useState } from 'react'
import createStore from 'stores'
import cheerio from 'cheerio'
import mixpanel from 'mixpanel-browser'

export * from './queryString'
export * from './pagination'

export const { dispatch, get: getStore } = createStore()

export const isSsr = typeof window === 'undefined'

mixpanel.init('ee65834e6f8773b76c357cca647d7438')

export const track = (name, props) => mixpanel.track(name, props)

export const formatDateP = (date) => {
  if (!date) {
    return null
  }

  const arr = date.split('-')

  return `${arr[1]}/${arr[2]}/${arr[0]}`
}

export const formatDateD = (date) => {
  if (!date) {
    return null
  }

  const arr = date.split('-')

  return `${arr[1]}/${arr[2]}/${arr[0]}`
}

export const genImgixUrl = (path) => {
  return `https://red-curtain-addict.imgix.net/${path}?auto=compression`
}

export const genImagePath = ({ ownerType, ownerId, name }) => {
  return `https://rca-s3.imgix.net/${ownerType}/${ownerId}/${name}`
}

export const cleanTitle = (str) => {
  let final = str
  final = final.replace(/&#8217/g, "'")
  final = final.replace(/&#8211/g, '-')
  final = final.replace(/&#8216/g, "'")

  return final
}

export const formatDate = (date) => {
  const dateStr = new Date(date).toLocaleDateString('en-US')
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  const dateArr = dateStr.split('/')
  const month = monthNames[dateArr[0] - 1]

  return `${month} ${dateArr[1]}, ${dateArr[2]}`
}

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }

      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

export const datesToString = ({
  startDate,
  endDate,
  dateFormat = 'MMM Do'
}) => {
  const startDateString = startDate && startDate.format(dateFormat)
  const endDateString = endDate && endDate.format(dateFormat)

  return `${startDateString || ''}${endDateString ? `- ${endDateString}` : ''}`
}

export const getFirstParagraph = (content) => {
  const contentHtml = cheerio.load(content)
  const pArray = contentHtml('p').toArray()
  let text = ''
  let count = 0

  while (!text) {
    text = contentHtml(pArray[count]).text()
    count++
  }

  return text
}

export const shuffleArray = (array) => {
  const newArray = [...array]

  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[newArray[i], newArray[j]] = [newArray[j], newArray[i]]
  }

  return newArray
}

export const toggleArr = (arr, value) => {
  if (arr.some((i) => i === value)) {
    return arr.filter((i) => i !== value)
  } else {
    return [...arr, value]
  }
}

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const venueAuthUser = (user, venueOwnerId) => {
  if (user) {
    //check for admin
    if (user.isAdmin) {
      return true
    }
    //check to is venue owner
    if (!user.isVenueOwner) {
      return false
    }
    //check for venue ownership
    if (venueOwnerId) {
      if (user.id == venueOwnerId) {
        return true
      }
    }

    return false
  } else {
    return false
  }
}

export const isFirstTimeVisitor = () => {
  const localStorageKey = 'visited'

  if (typeof localStorage !== 'undefined') {
    const visitedTimestamp = localStorage.getItem(localStorageKey)
    const oneDayInMillis = 24 * 60 * 60 * 1000 // 1 day in milliseconds
    const currentTime = Date.now()

    if (!visitedTimestamp || currentTime - visitedTimestamp > oneDayInMillis) {
      console.log('hartley visit check')
      // Set current timestamp in local storage
      window.localStorage.setItem(localStorageKey, currentTime)
      return true // First-time visitor
    }

    return false // Returning visitor
  } else {
    return false
  }
}
