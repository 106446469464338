import React, { useEffect, useState, useMemo } from 'react'
import {
  HomepageWrapper,
  TopWrapper,
  TitleWrapper,
  SecondSection,
  EventSection,
  CityGuideLink,
  ThirdSection,
  FourthSection,
  NewsContainer,
  SignUpSection,
  MobileSection,
  CityGuideWrapper,
  FireworkContainer,
  EventsWrapper,
  IgContainer,
  RenderedArticles,
  NewsArticlesWrapper,
  SomethingNewContainer,
  TopPicks,
  TopPicksWrapper,
  RecWrapper,
  RecBackground,
  SignInTag,
  NoFollowWrapper,
  NoFollowImages,
  NoFollowInfo,
  NoFollowGridSquare,
  NoFollowFlex,
  FollowedVenuesWrapper,
  VenueCard,
  ImageLabel,
  VenueWrapper,
  VenueButtonWrapper,
  BlurProtector,
  PaddingWrapper,
  HeaderWrapper,
  IconWrapper,
  VenueMobileSection,
  VenueSelectWrapper,
  SomethingNewMobileContainer,
  SomethingNewMobileImage,
  SomethingNewMobileBody,
  VideoWrapper,
  VideoContent,
  VideoOverlay
} from './styled'
import {
  EventSearchBar,
  EventCard,
  MobileEventCard,
  MaxWidthContainer,
  CtaBanner,
  ArticleCard,
  Button,
  Carousel,
  TopPickCard,
  Loader,
  CtaMultiBanner,
  Icon,
  SearchModal
} from 'components'
import { useQuery, gql, useLazyQuery } from '@apollo/client'
import { useStoreon } from 'storeon/react'
import { genImagePath, track } from 'utils'
import { format } from 'date-fns'
import buttercms from 'buttercms'
import { preTags } from 'constant'
import { Tooltip } from 'react-tooltip'
import { useHistory, useLocation } from 'react-router-dom'

const GET_ARTIST_SPOTLIGHT = gql`
  query artistSpotlight {
    artist_spotlight(order_by: { id: asc }) {
      id
      title
      description
      image
      button
      link
      is_outside_link
    }
  }
`

const GET_MAJOR_CITIES = gql`
  query cities {
    major_cities(order_by: { cid: asc }) {
      cid
      description
      id
      image_name
      name
    }
  }
`

const GET_EVENTS = gql`
  query getevents($limit: Int!, $where: events_bool_exp = {}) {
    events(limit: $limit, order_by: { start_date: asc }, where: $where) {
      id
      location
      end_date
      cover_image_id
      about
      major_city
      min_price
      name
      slug
      start_date
      event_images {
        id
        name
      }
      event_tags {
        tag {
          name
        }
      }
    }
  }
`

const GET_TOP_PICKS = gql`
  query topPicks($where: top_picks_bool_exp = {}) {
    top_picks(limit: 10, where: $where, order_by: { order: asc }) {
      order
      major_city_cid
      event {
        id
        name
        slug
        cover_image_id
        start_date
        end_date
        event_images {
          id
          name
        }
      }
    }
  }
`

const GET_FOLLOWED_VENUES = gql`
  query venues($userId: Int!) {
    venues(
      order_by: { name: asc }
      where: { user_followed_venues: { user_id: { _eq: $userId } } }
    ) {
      id
      name
      city
      state
      about
      slug
      venue_tags {
        tag {
          name
        }
      }
      venue_image {
        name
      }
      user_followed_venues {
        user_id
      }
    }
  }
`

const GET_FOLLOWED_VENUE_EVENTS = gql`
  query events($venueId: Int!, $currentDate: date!) {
    events(
      order_by: { name: asc, start_date: asc }
      where: { venue_id: { _eq: $venueId }, end_date: { _gte: $currentDate } }
    ) {
      id
      location
      end_date
      cover_image_id
      about
      major_city
      min_price
      name
      slug
      start_date
      event_images {
        id
        name
      }
      event_tags {
        tag {
          name
        }
      }
    }
  }
`

const tooltipContent =
  'Hover over an arts organization below to see their upcoming performances and CLICK to visit their page!'

const mobileTooltipContent =
  'Click on an arts organization below to see their upcoming performances and Click "See More" to visit their page!'

const Homepage = () => {
  const { isMobile, userData, showSearchModal } = useStoreon(
    'isMobile',
    'userData',
    'showSearchModal'
  )
  const history = useHistory()
  const location = useLocation()
  const categoryObject = preTags.find((item) => item.category === 'Type')
  const [venueSlide, setVenueSlide] = useState(0)
  const [selectedVenue, setSelectedVenue] = useState({
    name: '',
    slug: '',
    id: ''
  })
  const [chunkedEvents, setChunkedEvents] = useState()
  const [chunkedEventsLoading, setChunkedEventsLoading] = useState(false)
  const { loading: spotlightLoading, data: spotlightData } =
    useQuery(GET_ARTIST_SPOTLIGHT)
  const [getFollowedVenues, { loading: venuesLoading, data: venuesData }] =
    useLazyQuery(GET_FOLLOWED_VENUES, { fetchPolicy: 'network-only' })
  const [getVenueEvents, { loading: venueEventLoading, data: venueEventData }] =
    useLazyQuery(GET_FOLLOWED_VENUE_EVENTS)
  const [getTopPicks, { loading: topPicksLoading, data: topPicksData }] =
    useLazyQuery(GET_TOP_PICKS, { fetchPolicy: 'network-only' })

  const butter = buttercms('dec2b11b1e450ccee111a5e3f225c6992437bb01')
  const newDate = format(new Date(), 'P')
  const tagNames = userData?.tags?.map((i) => i.label) || []
  const cityIds = userData?.cities || []
  const { loading: citiesLoading, data: majorCitiesData } =
    useQuery(GET_MAJOR_CITIES)
  const [latestArticles, setLatestArticles] = useState()
  const { loading: eventLoading, data: latestEventsData } = useQuery(
    GET_EVENTS,
    {
      variables: {
        limit: 12,
        where: {
          _or: [
            { start_date: { _gte: newDate } },
            { end_date: { _gte: newDate } }
          ],
          event_tags: userData?.tags?.length
            ? {
                tag: {
                  name: {
                    _in: tagNames
                  }
                }
              }
            : undefined,
          major_city: userData?.cities?.length
            ? {
                _in: cityIds
              }
            : undefined
        }
      }
    }
  )
  useEffect(() => {
    if (userData) {
      getFollowedVenues({
        variables: {
          userId: userData.id
        }
      })

      getTopPicks({
        variables: {
          where: {
            major_city_cid: {
              _eq: userData.cities[0]
            }
          }
        }
      })
    } else {
      getTopPicks({
        variables: {
          where: {
            major_city_cid: {
              _eq: 0
            }
          }
        }
      })
    }
  }, [userData, location])

  const artistSpot = spotlightData?.artist_spotlight
  const majorCities = majorCitiesData?.major_cities
  const latestEvents = latestEventsData?.events
  const topPicks = topPicksData?.top_picks
  const followedVenues = venuesData?.venues
  const venueEvents = venueEventData?.events

  const getLatestArticles = async () => {
    try {
      const { data } = await butter.post.list({
        page: 1,
        page_size: 4,
        exclude_body: true
      })

      return data
    } catch (e) {
      console.error('Failed to get latest articles with error: ', e)
    }
  }

  useEffect(() => {
    getLatestArticles().then((data) => {
      setLatestArticles(data?.data)
    })
  }, [])

  useEffect(() => {
    track('View Landing Page')
  }, [])

  const loading = useMemo(() => {
    if (userData) {
      if (citiesLoading || topPicksLoading || spotlightLoading) {
        return true
      } else if (!majorCities || !artistSpot) {
        return true
      }
    } else {
      if (citiesLoading || topPicksLoading || spotlightLoading) {
        return true
      } else if (!majorCities || !artistSpot) {
        return true
      }
    }

    return false
  }, [
    citiesLoading,
    topPicksLoading,
    spotlightLoading,
    majorCities,
    artistSpot
  ])

  const latestEventsLoading = useMemo(() => {
    if (eventLoading) {
      return true
    } else if (!latestEvents) {
      return true
    }

    return false
  }, [eventLoading, latestEvents])

  const articlesLoading = useMemo(() => {
    if (!latestArticles) {
      return true
    }

    return false
  }, [latestArticles])

  const venueFollowedEventsLoading = useMemo(() => {
    if (venueEventLoading) {
      return true
    }

    return false
  }, [venueEventLoading])

  useEffect(() => {
    if (venueEvents) {
      setChunkedEventsLoading(true)
      const chunkSize = 4
      const tempChunks = []
      for (let i = 0; i < venueEvents?.length; i += chunkSize) {
        tempChunks.push(venueEvents.slice(i, i + chunkSize))
      }

      setChunkedEvents(tempChunks)
      setChunkedEventsLoading(false)
    }
  }, [venueEvents])

  const searchModal = useMemo(() => {
    if (showSearchModal) {
      return <SearchModal />
    }
  }, [showSearchModal])

  const venueMouseOver = (venue) => {
    if (venue.name !== selectedVenue.name) {
      setChunkedEventsLoading(true)
      getVenueEvents({
        variables: {
          venueId: venue.id,
          currentDate: new Date() // Pass the current date
        },
        fetchPolicy: 'network-only'
      })

      setSelectedVenue({ name: venue.name, slug: venue.slug, id: venue.id })
    }
  }

  const renderArticle = (item, idx) => {
    return <ArticleCard key={idx} article={item} />
  }

  const renderEvent = (item, idx) => {
    return <EventCard key={idx} data={item} />
  }

  const renderMobileEvent = (item, idx) => {
    return <MobileEventCard key={idx} data={item} />
  }

  const renderTopPick = (item, idx) => {
    return <TopPickCard key={idx} data={item} />
  }

  const renderCityLink = (item, idx) => {
    const { name, cid, id, image_name: imageName } = item
    const imageUrl = genImagePath({
      ownerType: 2,
      ownerId: id,
      name: imageName
    })

    return (
      <CityGuideLink
        key={idx}
        image={`${imageUrl}?height=200&auto=compression`}
        to={`/events?l=${cid}`}
      >
        <div>{name}</div>
      </CityGuideLink>
    )
  }

  const renderCityGuides = () => {
    if (isMobile) {
      return (
        <MobileSection itemWidth={'44vw'} style={{ paddingTop: 20 }}>
          <h3 style={{ paddingLeft: !isMobile ? 32 : 0 }}>City Guides</h3>
          <div>
            {majorCities.slice(0, 4).map(renderCityLink)}
            {renderCityLink(majorCities[5])}
            {renderCityLink(majorCities[6])}
            {renderCityLink(majorCities[12])}
            {renderCityLink(majorCities[13])}
          </div>
        </MobileSection>
      )
    }

    return (
      <Carousel label="City Guides" style={{ paddingTop: 40 }}>
        <CityGuideWrapper>
          {majorCities.slice(0, 4).map(renderCityLink)}
        </CityGuideWrapper>
        <CityGuideWrapper>
          {renderCityLink(majorCities[5])}
          {renderCityLink(majorCities[6])}
          {renderCityLink(majorCities[12])}
          {renderCityLink(majorCities[13])}
        </CityGuideWrapper>
        {/* <CityGuideWrapper>
          {majorCities.slice(8, 12).map(renderCityLink)}
        </CityGuideWrapper>
        <CityGuideWrapper>
          {majorCities.slice(12, 14).map(renderCityLink)}
          {majorCities.slice(0, 2).map(renderCityLink)}
        </CityGuideWrapper> */}
      </Carousel>
    )
  }

  const renderEvents = () => {
    const eventLabel = userData?.firstName
      ? `Curated for ${userData.firstName}`
      : 'Upcoming Events'

    if (latestEventsLoading) {
      return (
        <div
          style={{
            height: '100%',
            margin: 'auto',
            fontSize: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <p>Loading</p>
          <div style={{ marginLeft: '10px' }}>
            <Loader />
          </div>
        </div>
      )
    }

    if (isMobile) {
      return (
        // <EventSection>
        //   <Carousel label={eventLabel}>
        //     {latestEvents?.slice(0, 8).map(renderEvent)}
        //   </Carousel>
        // </EventSection>
        <MobileSection itemWidth={'44vw'}>
          <h3>{eventLabel}</h3>
          <div>{latestEvents?.slice(0, 8).map(renderMobileEvent)}</div>
        </MobileSection>
      )
    }

    return (
      <EventSection>
        <Carousel label={eventLabel}>
          <EventsWrapper>
            {latestEvents?.slice(0, 4).map(renderEvent)}
          </EventsWrapper>
          {latestEvents?.length > 4 && (
            <EventsWrapper>
              {latestEvents?.slice(4, 8).map(renderEvent)}
            </EventsWrapper>
          )}
          {latestEvents?.length > 8 && (
            <EventsWrapper>
              {latestEvents?.slice(8, 12).map(renderEvent)}
            </EventsWrapper>
          )}
        </Carousel>
      </EventSection>
    )
  }

  const renderArticles = () => {
    if (articlesLoading) {
      return (
        <div
          style={{
            height: '100%',
            margin: 'auto',
            fontSize: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <p>Loading</p>
          <div style={{ marginLeft: '10px' }}>
            <Loader />
          </div>
        </div>
      )
    }

    return (
      <NewsArticlesWrapper>
        <RenderedArticles>
          {latestArticles?.slice(0, 2).map(renderArticle)}
        </RenderedArticles>
        {!isMobile && (
          <RenderedArticles>
            {latestArticles?.slice(2, 4).map(renderArticle)}
          </RenderedArticles>
        )}
      </NewsArticlesWrapper>
    )
  }

  const renderTopPicks = () => {
    if (isMobile) {
      return (
        // <TopPicks>
        //   <Carousel label='See Our Top Picks'>
        //     {topPicks.map(renderTopPick)}
        //   </Carousel>
        // </TopPicks>
        <MobileSection itemWidth={'185px'} $firstChildMargin={10}>
          <h3>See Our Top Picks</h3>
          <div>{topPicks.map(renderTopPick)}</div>
        </MobileSection>
      )
    }

    return (
      <TopPicks>
        <Carousel label="See Our Top Picks">
          <TopPicksWrapper>
            {topPicks?.slice(0, 5).map(renderTopPick)}
          </TopPicksWrapper>
          {topPicks?.length > 5 && (
            <TopPicksWrapper>
              {topPicks?.slice(5, 10).map(renderTopPick)}
            </TopPicksWrapper>
          )}
        </Carousel>
      </TopPicks>
    )
  }

  const sendToVenue = () => {
    gtag('venue', 'venue_clicked', {
      venue_clicked: 1
    })

    history.push(`/venue/${selectedVenue.slug}`)
  }

  const renderNoFollowImages = () => {
    const noFollowImages = categoryObject.tags.map((tag) => tag.thumbnailUrl)
    const gridAreas = [
      '1 / 1 / 2 / 2',
      '1 / 2 / 2 / 3',
      '1 / 3 / 2 / 4',
      '2 / 1 / 3 / 2',
      '2 / 2 / 3 / 3',
      '2 / 3 / 3 / 4'
    ]
    const mobileGridAreas = [
      '1 / 1 / 2 / 2',
      '1 / 2 / 2 / 3',
      '2 / 1 / 3 / 2',
      '2 / 2 / 3 / 3'
    ]

    if (isMobile) {
      return noFollowImages.slice(0, 4).map((image, index) => (
        <NoFollowGridSquare
          key={index}
          $image={image}
          style={{ gridArea: `${mobileGridAreas[index]}` }}
        >
          <img
            src={'https://red-curtain-addict.imgix.net/homepage/lockIcon.svg'}
          />
        </NoFollowGridSquare>
      ))
    }

    return noFollowImages.map((image, index) => (
      <NoFollowGridSquare
        key={index}
        $image={image}
        style={{ gridArea: `${gridAreas[index]}` }}
      >
        <img
          src={'https://red-curtain-addict.imgix.net/homepage/lockIcon.svg'}
        />
      </NoFollowGridSquare>
    ))
  }

  const RenderFollowedVenues = () => {
    const chunkSize = 4
    const chunkedVenues = []
    for (let i = 0; i < followedVenues?.length; i += chunkSize) {
      chunkedVenues.push(followedVenues.slice(i, i + chunkSize))
    }

    if (!userData || (followedVenues?.length < 1 && !venuesLoading)) {
      return (
        <>
          {!userData && (
            <RecWrapper>
              <RecBackground />
              <BlurProtector>
                <h1>Get arts recommendations customized for you!</h1>
                <Button to={`/signup`}>Get Started</Button>
                <SignInTag href="/login">Sign In</SignInTag>
              </BlurProtector>
            </RecWrapper>
          )}
          <NoFollowWrapper>
            <NoFollowFlex>
              <NoFollowInfo>
                <h1>Organizations</h1>
                <h1>You Follow</h1>
                <p>
                  Easily see what's happening next at your favorite arts
                  destinations
                </p>
                {!isMobile && (
                  <Button to={userData ? `/artorganizations` : `/signup`}>
                    {userData ? `Select Art Organizations` : `Sign Up`}
                  </Button>
                )}
              </NoFollowInfo>
              <NoFollowImages>{renderNoFollowImages()}</NoFollowImages>
              {isMobile && (
                <div style={{ marginTop: 15, width: '100%' }}>
                  <Button to={userData ? `/artorganizations` : `/signup`}>
                    {userData ? `Select Art Organizations` : `Sign Up`}
                  </Button>
                </div>
              )}
            </NoFollowFlex>
          </NoFollowWrapper>
        </>
      )
    } else if (venuesLoading) {
      return (
        <div
          style={{
            height: '100vh',
            margin: 'auto',
            fontSize: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <p>Loading</p>
          <div style={{ marginLeft: '10px' }}>
            <Loader />
          </div>
        </div>
      )
    } else if (followedVenues?.length > 0) {
      if (isMobile) {
        return (
          <FollowedVenuesWrapper>
            <VenueMobileSection>
              <HeaderWrapper>
                <h3>{`Organizations You Follow`}</h3>
                <IconWrapper id={'carousel-header'}>
                  <Icon name={'questionMark'} />
                </IconWrapper>
                <Tooltip
                  className="carousel-tooltip"
                  anchorSelect="#carousel-header"
                  content={mobileTooltipContent}
                  place={'bottom'}
                  offset={6}
                  opacity={1}
                />
              </HeaderWrapper>
              <VenueSelectWrapper>
                {followedVenues.map((venue, index) => {
                  let imageUrl = ''
                  if (venue.venue_image) {
                    imageUrl = genImagePath({
                      ownerType: 4,
                      ownerId: venue?.id,
                      name: venue?.venue_image.name
                    })
                  }

                  return (
                    <div key={`venue-${index}`}>
                      <VenueCard
                        $image={imageUrl}
                        $isSelected={venue.id === selectedVenue.id}
                        onMouseOver={() => venueMouseOver(venue)}
                      />
                      <ImageLabel>{venue.name}</ImageLabel>
                    </div>
                  )
                })}
              </VenueSelectWrapper>
            </VenueMobileSection>
            {venueFollowedEventsLoading ? (
              <div
                style={{
                  margin: 'auto',
                  fontSize: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100px',
                  marginTop: '25px'
                }}
              >
                <p>Loading</p>
                <div style={{ marginLeft: '10px' }}>
                  <Loader />
                </div>
              </div>
            ) : (
              <>
                {venueEvents?.length > 0 ? (
                  <>
                    <MobileSection
                      itemWidth={'44vw'}
                      style={{ padding: '30px 0px' }}
                    >
                      <h3>{`Upcoming Events for ${selectedVenue.name}`}</h3>
                      <div>
                        {venueEvents?.slice(0, 8).map(renderMobileEvent)}
                      </div>
                    </MobileSection>
                  </>
                ) : (
                  selectedVenue.name !== '' && (
                    <div
                      style={{
                        margin: 'auto',
                        fontSize: '20px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100px',
                        marginTop: '25px'
                      }}
                    >
                      <p>No Upcoming Events</p>
                    </div>
                  )
                )}
                {selectedVenue.name !== '' && (
                  <VenueButtonWrapper>
                    <Button onClick={() => sendToVenue()}>See More</Button>
                  </VenueButtonWrapper>
                )}
              </>
            )}
          </FollowedVenuesWrapper>
        )
      }

      return (
        <FollowedVenuesWrapper>
          <Carousel
            label={`Organizations You Follow`}
            tooltip={true}
            tooltipPlace={'right'}
            tooltipContent={tooltipContent}
            slide={venueSlide}
            setSlide={setVenueSlide}
          >
            {chunkedVenues.map((array, index) => (
              <VenueWrapper key={`venue-wrap-${index}`}>
                {array.map((venue, index) => {
                  let imageUrl = ''
                  if (venue.venue_image) {
                    imageUrl = genImagePath({
                      ownerType: 4,
                      ownerId: venue?.id,
                      name: venue?.venue_image.name
                    })
                  }

                  return (
                    <div key={`venue-${index}`}>
                      <VenueCard
                        $image={imageUrl}
                        $isSelected={venue.id === selectedVenue.id}
                        onMouseEnter={() => venueMouseOver(venue)}
                        onClick={() => sendToVenue()}
                      />
                      <ImageLabel>{venue.name}</ImageLabel>
                    </div>
                  )
                })}
              </VenueWrapper>
            ))}
          </Carousel>
          {venueFollowedEventsLoading || chunkedEventsLoading ? (
            <div
              style={{
                margin: 'auto',
                fontSize: '20px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100px'
              }}
            >
              <p>Loading</p>
              <div style={{ marginLeft: '10px' }}>
                <Loader />
              </div>
            </div>
          ) : (
            <>
              {chunkedEvents?.length > 0 && !chunkedEventsLoading ? (
                <Carousel label={`Upcoming Events for ${selectedVenue.name}`}>
                  {chunkedEvents.map((array, index) => (
                    <EventsWrapper key={`event-${index}`}>
                      {array.map(renderEvent)}
                    </EventsWrapper>
                  ))}
                </Carousel>
              ) : null}

              {selectedVenue.name !== '' &&
              !chunkedEventsLoading &&
              chunkedEvents?.length === 0 ? (
                <div
                  style={{
                    margin: 'auto',
                    fontSize: '20px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100px'
                  }}
                >
                  <p>No Upcoming Events</p>
                </div>
              ) : null}
              {selectedVenue.name !== '' && (
                <VenueButtonWrapper>
                  <Button to={`/venue/${selectedVenue.slug}`}>See More!</Button>
                </VenueButtonWrapper>
              )}
            </>
          )}
        </FollowedVenuesWrapper>
      )
    }
  }

  if (loading) {
    return (
      <div
        style={{
          height: '100vh',
          margin: 'auto',
          fontSize: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <p>Loading</p>
        <div style={{ marginLeft: '10px' }}>
          <Loader />
        </div>
      </div>
    )
  }

  return (
    <HomepageWrapper>
      {/* <TopWrapper>
        <EventSearchBar reroute />
        <TitleWrapper>
          <h1>Your source for the arts.</h1>
          <h2>Search events, concerts, artists, and much, MUCH more!</h2>
        </TitleWrapper>
      </TopWrapper> */}
      <VideoWrapper>
        <VideoOverlay />
        <video
          src="https://red-curtain-addict.imgix.net/homepage/Bannerv2.mp4"
          autoPlay
          loop
          muted
          controls={false}
          playsInline
          style={{ pointerEvents: 'none' }}
        />
        <VideoContent>
          <EventSearchBar reroute />
          <TitleWrapper>
            <h1>Your source for the arts.</h1>
            <h2>Search performances, get recommendations, and more!</h2>
          </TitleWrapper>
        </VideoContent>
      </VideoWrapper>
      <SecondSection user={userData}>
        <div>
          {/* <CtaBanner
            shiftLeft
            image="https://rca-s3.imgix.net/static/custom.jpeg?compression=auto&height=500"
            title="Customize your arts experience!"
            description="Tell us more about yourself to receive a curated list of arts experiences just for you!"
            ctaLabel="Sign up!"
            link="/signup"
          /> */}
          {topPicks?.length > 0 && renderTopPicks()}
        </div>
      </SecondSection>
      <ThirdSection>
        {renderEvents()}
        {RenderFollowedVenues()}
      </ThirdSection>
      <FourthSection>
        <PaddingWrapper>
          <CtaMultiBanner spotlights={artistSpot} />
        </PaddingWrapper>
        <PaddingWrapper>{renderCityGuides()}</PaddingWrapper>
        <PaddingWrapper style={{ marginTop: '30px', marginBottom: '30px' }}>
          <FireworkContainer user={userData}>
            <h3>Performance Spotlights</h3>
            <MaxWidthContainer>
              <fw-embed-feed
                channel="red_curtain_addict"
                playlist="5nl7Qg"
                mode="row"
                open_in="default"
                max_videos="0"
                placement="middle"
                player_placement="bottom-right"
                branding="false"
              />
            </MaxWidthContainer>
          </FireworkContainer>
        </PaddingWrapper>
        <MaxWidthContainer>
          {!isMobile && (
            <SomethingNewContainer>
              <h1>Looking for Something New?</h1>
              <div>
                <p>Discover new adventures!</p>
                <p>
                  Let us guide you to exciting local arts experiences happening
                  near you.
                </p>
              </div>
              <Button to={'/somethingnew'}>Explore Something New</Button>
            </SomethingNewContainer>
          )}
          {isMobile && (
            <SomethingNewMobileContainer>
              <SomethingNewMobileImage />
              <SomethingNewMobileBody>
                <h2>Looking for Something New?</h2>
                <p>
                  Discover new adventures! Let us guide you to exciting local
                  arts experiences happening near you.
                </p>
                <Button to={'/somethingnew'}>Explore Something New</Button>
              </SomethingNewMobileBody>
            </SomethingNewMobileContainer>
          )}
          <NewsContainer>
            <h3>Latest News</h3>
            {renderArticles()}
            <Button to={'/articles'}>Read More Articles</Button>
          </NewsContainer>
          <CtaBanner
            image="https://rca-s3.imgix.net/static/episodesbanner.jpg"
            title="Go backstage and behind the curtain!"
            description="Experience VIP backstage access to digital events around the world. Watch our latest episode!"
            ctaLabel="Watch"
            link="/episodes"
          />
        </MaxWidthContainer>
      </FourthSection>
      {!userData && (
        <SignUpSection>
          <div>
            <h2>Win tickets, merch and more. Sign up today!</h2>
            <p>Stay up-to-date with the latest events in your city.</p>
            <Button to="/signup">Get started</Button>
          </div>
        </SignUpSection>
      )}
      <MaxWidthContainer>
        <IgContainer>
          <h3>Let's be friends!</h3>
          <p>
            Follow us{' '}
            <a
              href="https://www.instagram.com/redcurtainaddict"
              target="_blank"
              rel="noreferrer"
            >
              @redcurtainaddict
            </a>
          </p>
          <MaxWidthContainer>
            <fw-embed-feed
              channel="red_curtain_addict"
              playlist="v6w735"
              mode="row"
              open_in="default"
              max_videos="0"
              placement="middle"
              player_placement="bottom-right"
              branding="false"
            />
          </MaxWidthContainer>
        </IgContainer>
      </MaxWidthContainer>
      {searchModal}
    </HomepageWrapper>
  )

  // return (
  //   <p>test</p>
  // );
}

export default Homepage
