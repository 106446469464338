import React from 'react'
import {
  PickWrapper,
  CoverImage,
  NumberImage,
  ImageWrapper,
  NameWrapper
} from './styled'
import { genImagePath, formatDateP } from 'utils'
import { majorCitiesIdx } from 'constant'
import { useStoreon } from 'storeon/react'

const TopPickCard = (props) => {
  const { isMobile } = useStoreon('isMobile')
  const {
    id,
    name,
    slug,
    start_date: startDate,
    end_date: endDate,
    cover_image_id: coverImageId,
    event_images: eventImages
  } = props.data.event
  const allImages = eventImages
  const coverImage =
    allImages.find((i) => i.id === coverImageId) || allImages[0] || {}
  const imageUrl = genImagePath({
    ownerType: 1,
    ownerId: id,
    name: coverImage.name
  })

  return (
    <PickWrapper to={`/events/${slug}`}>
      <ImageWrapper>
        <NumberImage
          $isImageTen={props.data.order === 10}
          image={`https://red-curtain-addict.imgix.net/homepage/${props.data.order}.png`}
        >
          <CoverImage
            $isImageTen={props.data.order === 10}
            image={`${imageUrl}`}
          />
        </NumberImage>
      </ImageWrapper>

      <NameWrapper>
        <h4>{name}</h4>
        {endDate ? (
          <p>
            {formatDateP(startDate)} - {formatDateP(endDate)}
          </p>
        ) : (
          <p>{formatDateP(startDate)}</p>
        )}
      </NameWrapper>
    </PickWrapper>
  )
}

export default TopPickCard
