import React, { useEffect, useMemo, useState } from 'react'
import { frontloadConnect } from 'react-frontload'
import { connectStoreon } from 'storeon/react'
import buttercms from 'buttercms'
import {
  ArticleLayout,
  ArticleWrapper,
  ContentFrame,
  InjectedItems,
  ArticleContent,
  LatestArticle,
  ArticleBody,
  RelatedArticles
} from './styled'
import { useParams } from 'react-router-dom'
import { formatDate } from 'utils'
import { Helmet } from 'react-helmet'
import { Banner, ArticleCard, Loader } from 'components'
import { useStoreon } from 'storeon/react'
import { SearchModal } from 'components'

const fetchPost = async ({ dispatch, slug }) => {
  const butter = buttercms('dec2b11b1e450ccee111a5e3f225c6992437bb01')
  try {
    const { data } = await butter.post.retrieve(slug)
    let { data: relatedData } = await butter.post.list({
      page: 1,
      page_size: 12,
      exclude_body: true,
      category_slug: data.data.categories[0]?.slug
    })

    if (relatedData.meta.count === 1) {
      const { data: latestData } = await butter.post.list({
        page: 1,
        page_size: 12,
        exclude_body: true
      })

      relatedData = latestData
    }

    dispatch('setArticle', data.data)

    const relatedWithoutCurrent = relatedData.data.filter(
      (rd) => rd.slug !== slug
    )

    dispatch('setRelatedArticles', relatedWithoutCurrent)
  } catch (e) {
    console.error('page load error: ', e.message)
  }
}

const Article = ({ article, relatedArticles, isMobile, dispatch }) => {
  const {showSearchModal} = useStoreon('showSearchModal');
  const { slug } = useParams()

  const loading = useMemo(() => {
    if (article && relatedArticles) {
      return false;
    } else {
      return true;
    }
  }, [article, relatedArticles]);

  useEffect(() => {
    if (slug !== article?.slug) {
      dispatch('setArticle', null)
      dispatch('setRelatedArticles', null)
    }
  }, [article, dispatch, slug])

  useEffect(() => {
    if (!article) {
      fetchPost({ dispatch, slug })
    }
  }, [article, dispatch, slug])

  const searchModal = useMemo(() => {
    if (showSearchModal) {
      return (
        <SearchModal />
      )            
    }
  }, [showSearchModal])

  if (!article) {
    return <ArticleLayout />
  }

  const {
    featured_image: featuredImage,
    body,
    title,
    summary,
    published
  } = article

  const bannerContent = (
    <Banner image={featuredImage} backgroundPosition="center top">
      <ArticleContent>
        <LatestArticle>
          <h2>{title}</h2>
          <div>{formatDate(published)}</div>
        </LatestArticle>
      </ArticleContent>
    </Banner>
  )

  const renderRelated = (item, idx) => {
    return <ArticleCard key={idx} article={item} small />
  }

  if (loading) {
    return (
      <div style={{height: '100vh', margin: 'auto', fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <p>Loading</p>
        <div style={{marginLeft: '10px'}}>
          <Loader/>
        </div>
      </div>
    )
  }

  return (
    <ArticleLayout>
      <ArticleWrapper image={featuredImage} isMobile={isMobile}>
        <Helmet>
          <title>Red Curtain Addict - {title || ''}</title>
          <meta property="og:title" content={title || ''} />
          <meta property="og:description" content={summary || ''} />
          <meta property="og:image" content={featuredImage} />
        </Helmet>
        {bannerContent}
        <ArticleBody isMobile={isMobile}>
          <ContentFrame isMobile={isMobile}>
            <InjectedItems isMobile={isMobile}>
              <div dangerouslySetInnerHTML={{ __html: body }} />
            </InjectedItems>
          </ContentFrame>
          <RelatedArticles isMobile={isMobile}>
            <h4>Related Articles</h4>
            <div>{relatedArticles?.map(renderRelated)}</div>
          </RelatedArticles>
        </ArticleBody>
        {searchModal}
      </ArticleWrapper>
    </ArticleLayout>
  )
}

export default connectStoreon(
  'isMobile',
  'article',
  'relatedArticles',
  frontloadConnect(async ({ dispatch, match }) => {
    await fetchPost({ dispatch, slug: match.params.slug })
  })(Article)
)
