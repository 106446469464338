import styled from 'styled-components'
import { colors, breakpoints } from 'constant'

const { md } = breakpoints

export const GalleryModalWrapper = styled.div`
  width: 1000px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
`

export const ImageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

export const Image = styled.div`
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 300px;
  width: calc(100% / 3 - 8px);
  margin: 4px;
  cursor: pointer;

  ${md} {
    height: 200px;
    width: calc(100% / 2 - 8px);
  }
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 12px 12px 24px 12px;

  > h1 {
    ${md} {
      font-size: 24px;
    }
  }
`

export const CloseButton = styled.button`
  align-self: flex-end;
  margin-bottom: 12px;
  background: none;
  border: none;
  cursor: pointer;
  
  > svg {
    height: 34px;
    width: 34px;
    color: ${colors.mainText};
  }
`
